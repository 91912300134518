import React from 'react';
import { LazyMotion, domAnimation, m } from 'framer-motion';

const variants = {
  hidden: { opacity: 0, x: -20, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 20, y: 0 },
};

export default function AnimatedLayout({ children }) {
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial="hidden"
        animate="enter"
        exit="exit"
        variants={variants}
        transition={{ type: 'keyframes' }}
      >
        {children}
      </m.div>
    </LazyMotion>
  );
}
